'use client';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import CustomPrismicText from '@/components/prismic/customPrismicText';
import TextFormField from '@/components/form/textFormField';

/**
 * @typedef {import("@prismicio/client").Content.HubspotSliceSlice} HubspotSliceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HubspotSliceSlice>} HubspotSliceProps
 * @param {HubspotSliceProps}
 */

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  firstname: Yup.string().required('Check firstname and try again'),
  lastname: Yup.string().required('Check lastname and try again'),
  message: Yup.string().required('Check message and try again'),
});

const styles = {
  sliceWrapper: {
    backgroundImage:
      'linear-gradient(170deg, #FFF 42%,  #2e3039 calc(42% + 2px))',
  },
  contentWrapper: {
    maxWidth: '1140px;',
    margin: '0 auto',
    padding: '100px 0px',
    display: 'flex',
    flexDirection: { xs: 'column-reverse', sm: 'row' },
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  formContainer: {
    width: '100%',
    maxWidth: '528px',
    padding: { xs: '20px', sm: '50px' },
    backgroundColor: '#FAFAFA',
    borderRadiusLeft: '9px',
  },
  descriptionTextContainer: {
    width: '100%',
    minHeight: '500px',
    maxWidth: '550px',
    padding: { xs: '20px', sm: '10px' },
    backgroundSize: 'cover',
  },
  titleText: {
    fontSize: { xs: '30px', sm: '40px' },
    lineHeight: '49px',
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
  },

  submitButton: {
    marginTop: '24px',
  },
  policyText: {
    lineHeight: 1,
    marginTop: '18px',
    textAlign: 'center',
  },
  textRow: {
    marginTop: '45px',
    display: 'flex',
    alignItems: 'center',
  },
  checkMarkImage: {
    height: '38px',
  },
  listedTextItem: {
    maxWidth: '308px',
    marginLeft: '31px',
    fontSize: '20px',
    lineHeight: '30px',
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
  },
  formSubmittedText: {
    maxWidth: '528px',
    padding: '50px',
    textAlign: 'center',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

const HubspotSlice = ({ slice }) => {
  const {
    primary: { title, description, submit_button_text, image },
  } = slice;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const renderSubmitButton = (buttonText) => {
    if (buttonText) {
      return <CustomPrismicText field={buttonText} />;
    }
    return <Typography component='span'>Submit</Typography>;
  };

  const handleFormSubmit = async ({ email, firstname, lastname, message }) => {
    try {
      const body = { email, firstname, lastname, message };
      await fetch('/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      setIsFormSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  const initValues = {
    email: '',
    firstname: '',
    lastname: '',
    message: '',
  };

  return (
    <Box
      id={slice.slice_type}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      component='section'
      sx={styles.sliceWrapper}>
      <Box sx={styles.contentWrapper}>
        <Box sx={styles.formContainer}>
          {isFormSubmitted ? (
            <Box sx={styles.formSubmittedText}>
              <Typography sx={styles.listedTextItem}>
                {'Thank you'} <br /> {'I will contact you soon'}
              </Typography>
            </Box>
          ) : (
            <Formik
              initialValues={initValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}>
              {({ handleSubmit, isSubmitting, isValid }) => (
                <>
                  <CustomPrismicText sx={styles.titleText} field={title} />
                  <CustomPrismicText
                    sx={styles.description}
                    field={description}
                  />
                  <form onSubmit={handleSubmit}>
                    {isSubmitting ? 'Loading' : null}
                    <Box sx={styles.content}>
                      <TextFormField name='email' label={'Email'} />
                      <TextFormField name='firstname' label={'First name'} />
                      <TextFormField name='lastname' label={'Last name'} />
                      <TextFormField
                        name='message'
                        label={'message'}
                        isMultiline
                        rows={5}
                        rowsMax={5}
                      />
                      <Button
                        variant='contained'
                        sx={styles.submitButton}
                        type='submit'
                        disabled={!isValid}>
                        {renderSubmitButton(submit_button_text)}
                      </Button>
                    </Box>
                  </form>
                </>
              )}
            </Formik>
          )}
        </Box>
        <Box
          sx={{
            ...styles.descriptionTextContainer,
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${image.url})`,
          }}></Box>
      </Box>
    </Box>
  );
};

HubspotSlice.propTypes = {
  sectionData: PropTypes.object,
};

export default HubspotSlice;
