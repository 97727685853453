'use client';

import { Box } from '@mui/material';
import CustomPrismicText from '@/components/prismic/customPrismicText';

/**
 * @typedef {import("@prismicio/client").Content.EmbedCodesandboxSlice} EmbedCodesandboxSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<EmbedCodesandboxSlice>} EmbedCodesandboxProps
 * @param {EmbedCodesandboxProps}
 */

const styles = {
  section: {
    padding: { xs: '100px 24px 50px', md: '100px 96px 50px' },
    color: 'white',
  },
  container: {
    maxWidth: '1248px',
    margin: '0 auto',
  },
};

const EmbedCodesandbox = ({ slice }) => {
  console.log(slice);
  const {
    primary: { embed_title: title, embed_url: embed },
  } = slice;
  return (
    <Box
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      component='section'
      sx={{
        ...styles.section,
        backgroundColor: '#2E3039',
      }}>
      <Box
        sx={styles.container}
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}>
        <CustomPrismicText field={title} sx={styles.title} />
        <Box
          dangerouslySetInnerHTML={{ __html: embed.html }}
          sx={styles.videoContainer}
        />
      </Box>
    </Box>
  );
};

export default EmbedCodesandbox;
