'use client';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CustomPrismicText from '../../components/prismic/customPrismicText';
import VideoBlock from '@/components/ui/videoBlock';
import { isFilled } from '@prismicio/client';
import SocialLinksList from '@/components/ui/socialLinksList';

/**
 * @typedef {import("@prismicio/client").Content.VideoSliceSlice} VideoSliceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<VideoSliceSlice>} VideoSliceProps
 * @param {VideoSliceProps}
 */

const styles = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: { xs: '50px 18px', sm: '100px 96px 100px' },
    color: 'white',
    overflow: 'hidden',
  },
  container: {
    maxWidth: '1248px',
    margin: { xs: 'unset', md: '0 auto' },
    width: '100%',
    position: 'relative',
    // overflowX: { xs: 'scroll', md: 'unset' },
    scrollBehavior: 'smooth',
  },
  content: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: '40px',
    justifyContent: { xs: 'unset', md: 'space-between' },
    alignItems: 'center',
    '&>div:nth-child(1)': {
      order: { xs: 2, sm: 1 },
    },
    '&>div:nth-child(2)': {
      order: { xs: 1, sm: 2 },
    },
  },

  textBlock: {
    maxWidth: { xs: '100%', md: '500px' },
  },
};

const VideoSlice = ({ slice }) => {
  const {
    primary: {
      bg_color: bgColor,
      background_image: bgImg,
      description,
      cover_image: coverImg,
      name,
      person_role: role,
      video_url: videoUrl,
      link_to_mp4_video: mp4Video,
      link_to_webm_video: webmVideo,
    },
    items,
  } = slice;

  return (
    <Box
      id='about'
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      sx={{
        ...styles.section,
        backgroundColor: bgColor || '#0F0F0F',
        backgroundImage: isFilled.image(bgImg) ? `url(${bgImg.url})` : 'unset',
        backgroundPositionY: '-9px',
      }}
      component='section'>
      <Box sx={styles.container}>
        <Box sx={styles.content}>
          <VideoBlock
            coverImg={coverImg}
            name={name}
            role={role}
            videoUrl={videoUrl}
            mp4Video={mp4Video}
            webmVideo={webmVideo}
          />
          <Box
            component={motion.div}
            sx={styles.textBlock}
            initial={{
              opacity: 0,
              x: 200,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{
              once: true,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 0.5,
            }}>
            <CustomPrismicText field={description} />
            <Box sx={{ mt: '20px' }}>
              <SocialLinksList socialLinks={items} width={26} height={26} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

VideoSlice.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default VideoSlice;
