'use client';
import { motion } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import { Box, Dialog, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomPrismicText from '../../components/prismic/customPrismicText';
import { PrismicNextImage } from '@prismicio/next';

const styles = {
  imgBlock: {
    width: { xs: '100%', sm: '350px', lg: '380px' },
    maxWidth: { xs: '300px', sm: 'unset' },
    height: { xs: '300px', sm: '350px', lg: '380px' },
  },
  videoBlock: {
    width: { xs: '100%', md: '390px', lg: '500px' },
    maxWidth: { xs: '300px', sm: '500px', md: 'unset' },
    height: { xs: '300px', sm: '500px', md: '400px', lg: '500px' },
    position: 'relative',
    borderRadius: '9px',
    cursor: 'pointer',
    transition: 'all .4s cubic-bezier(.33,1,.68,1)',
  },
  coverBlock: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: '40px',
    zIndex: 1,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    textAlign: 'left',
    borderRadius: '9px',
  },
  name: {
    fontSize: '20px',
    lineHeight: '25px',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  role: {
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 400,
    color: '#FFFFFF',
  },
  videoWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  fullVideContainer: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
    cursor: 'pointer',
    '& iframe': {
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      position: 'absolute',
    },
  },
  dialog: {
    backgroundColor: 'rgba(0,0,0,0.25)',
    backdropFilter: 'saturate(80%) blur(20px)',

    '& .MuiPaper-root': {
      flexDirection: 'column',
      justifyContent: 'center',
      height: { xs: '100%', md: 'unset' },
      backgroundColor: 'unset',
    },
  },
  closeBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    color: 'white',
    border: '1px solid white',
  },
};

const VideoBlock = ({
  coverImg,
  name,
  role,
  videoUrl,
  mp4Video,
  webmVideo,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isVideoProvided = false;

  const [hasTouch, setHasTouch] = useState();
  useEffect(() => {
    const isTouchDevice =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;

    setHasTouch(isTouchDevice);
  }, []);

  const [fullVideo, setFullVideo] = useState(null);
  const showFullVideo = (embedCode) => {
    setFullVideo(embedCode);
  };
  const closeFullVideo = () => {
    setFullVideo(null);
  };

  const playerRef = useRef();
  const coverRef = useRef();
  const playerContainerRef = useRef();

  // currentTime
  const playOnHover = () => {
    // 1. hideCoverImg
    coverRef.current.style.zIndex = 0;
    coverRef.current.style.opacity = 0;
    // 2. animate container
    //playerContainerRef.current.style.width = '600px';
    // 3. play video
    playerRef.current.play();
  };
  const onMouseLeave = () => {
    // 1. pause video and reset time
    playerRef.current.pause();
    playerRef.current.currentTime = 0;
    // 2. container
    // playerContainerRef.current.style.width = '400px';
    // 3. show cover img
    coverRef.current.style.zIndex = 1;
    coverRef.current.style.opacity = 1;
  };

  if (!isVideoProvided)
    return (
      <Box
        sx={styles.imgBlock}
        component={motion.div}
        initial={{
          opacity: 0,
          x: -200,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        viewport={{
          once: true,
        }}
        transition={{
          ease: 'easeInOut',
          duration: 0.8,
        }}>
        <PrismicNextImage
          field={coverImg}
          width={380}
          height={380}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
            borderRadius: '9px',
            filter: 'brightness(0.9)',
            boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
            transform: 'rotate(2deg)',
          }}
        />
      </Box>
    );

  return (
    <>
      <Box
        ref={playerContainerRef}
        sx={styles.videoBlock}
        onMouseEnter={isMobile || hasTouch ? undefined : () => playOnHover()}
        onMouseLeave={isMobile || hasTouch ? undefined : () => onMouseLeave()}
        onClick={() => showFullVideo(videoUrl)}>
        <Box
          ref={coverRef}
          sx={{
            ...styles.coverBlock,
            backgroundImage: `url(${coverImg.url || videoUrl.thumbnail_url})`,
          }}>
          <CustomPrismicText sx={styles.name} field={name} />
          <CustomPrismicText sx={styles.role} field={role} />
        </Box>

        {!isMobile && !hasTouch && (
          <Box sx={styles.videoWrapper}>
            <video
              ref={playerRef}
              playsInline
              loop
              muted
              preload='true'
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                objectFit: 'cover',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                borderRadius: '9px',
              }}>
              {mp4Video && <source type='video/mp4' src={mp4Video.url} />}
              {webmVideo && <source type='video/webm' src={webmVideo.url} />}
              Your browser does not support HTML5 video tag.
            </video>
          </Box>
        )}
      </Box>
      {!!fullVideo && (
        <Dialog
          fullScreen={isMobile}
          fullWidth
          maxWidth='md'
          open
          onClose={closeFullVideo}
          sx={styles.dialog}>
          {isMobile && (
            <IconButton sx={styles.closeBtn} onClick={closeFullVideo}>
              <CloseIcon />
            </IconButton>
          )}
          <Box
            sx={styles.fullVideContainer}
            dangerouslySetInnerHTML={{ __html: fullVideo.html }}
          />
        </Dialog>
      )}
    </>
  );
};

export default VideoBlock;
