'use client';
import PropTypes from 'prop-types';
import { Box, Grid, Button, useMediaQuery } from '@mui/material';
import CustomPrismicText from '../../components/prismic/customPrismicText';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';

/**
 * @typedef {import("@prismicio/client").Content.LaptopHorizontalSliceSlice} LaptopHorizontalSliceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<LaptopHorizontalSliceSlice>} LaptopHorizontalSliceProps
 * @param {LaptopHorizontalSliceProps}
 */

const styles = {
  section: {
    minHeight: '600px',
    position: 'relative',
    padding: { xs: '100px 18px', sm: '100px 96px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundSize: '70% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right -30%',
    backgroundColor: '#2E3039',
    color: 'white',
    overflowX: 'hidden',
  },
  container: {
    maxWidth: '1248px',
    margin: { xs: 'unset', md: '0 auto' },
    width: '100%',
    position: 'relative',
  },
  img: {
    maxWidth: '100%',
  },
  gridContainer: {
    alignItems: 'center',
    gap: { xs: '50px', sm: 'unset' },
  },
  btn: {
    marginTop: '20px',
  },
};

const LaptopHorizontalSlice = ({ slice }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const {
    primary: {
      slice_title: title,
      slice_description: description,
      image,
      button_link: btnLink,
      button_text: btnText,
      background_color: bgColor,
    },
  } = slice;
  return (
    <Box
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      component='section'
      sx={{
        ...styles.section,
        ...(bgColor ? { backgroundColor: bgColor } : {}),
      }}>
      <Box sx={styles.container}>
        <Grid container sx={styles.gridContainer} justifyContent='center'>
          <Grid item xs={12} sm={7} md={5}>
            <Box sx={styles.titleBlock}>
              <CustomPrismicText field={title} variant='h2' component='h2' />
            </Box>
            <CustomPrismicText field={description} />
            <Button
              sx={styles.btn}
              variant='outlined'
              component={PrismicNextLink}
              field={btnLink}>
              <CustomPrismicText field={btnText} />
            </Button>
          </Grid>
          <Grid item xs={12} sm={5} md={7}>
            <PrismicNextImage
              field={isMobile ? image.mobile : image}
              style={{ objectFit: 'cover', objectPosition: 'left' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

LaptopHorizontalSlice.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default LaptopHorizontalSlice;
