'use client';

import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import { isFilled, asText } from '@prismicio/client';
import CustomPrismicText from '../../components/prismic/customPrismicText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * @typedef {import("@prismicio/client").Content.FaqSliceSlice} FaqSliceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FaqSliceSlice>} FaqSliceProps
 * @param {FaqSliceProps}
 */

const styles = {
  section: {
    position: 'relative',
    padding: { xs: '24px 18px 48px ', sm: '100px 96px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  container: {
    maxWidth: '1248px',
    margin: { xs: 'unset', md: '0 auto' },
    width: '100%',
    position: 'relative',
    overflowX: { xs: 'scroll', md: 'unset' },
    scrollBehavior: 'smooth',
  },
  img: {
    maxWidth: '100%',
  },
  gridContainer: {
    alignItems: 'center',
  },
  titleBlock: {
    textAlign: 'center',
  },
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, .125)',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#FAFAFA',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FaqSlice = ({ slice }) => {
  const {
    primary: { bg_color: bgColor, slice_title },
    items = [],
  } = slice;
  return (
    <Box
      id='faq'
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      component='section'
      sx={{
        ...styles.section,
        backgroundColor: bgColor || '#fff',
      }}>
      <Box sx={styles.container}>
        <Grid container sx={styles.gridContainer} justifyContent='center'>
          <Grid item xs={12} xl={8}>
            <Box sx={styles.titleBlock}>
              <CustomPrismicText
                sx={styles.title}
                variant='h2'
                component='h2'
                field={slice_title}
              />
            </Box>

            {items.map(({ answer, question }, index) => {
              if (isFilled.richText(question) && isFilled.richText(answer))
                return (
                  <Accordion
                    key={asText(question)}
                    defaultExpanded={index === 0 ? true : false}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CustomPrismicText field={question} component='h3' />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomPrismicText field={answer} />
                    </AccordionDetails>
                  </Accordion>
                );
              return null;
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

FaqSlice.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default FaqSlice;
